(function() {
    var notifier = document.querySelector('meta[name=notifier]');
    if (!notifier) {
        return;
    }

    var init = function() {
        Sentry.init({ dsn: notifier.getAttribute('content') })
    };

    if (window.Sentry) {
        init()
    } else {
        document.getElementById('sentry-js').addEventListener('load', init)
    }
})();
